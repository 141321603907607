@import '@styles/variables';

.stepper-item {
  display: none;
  position: fixed;
  top: 50%;
  right: 20px;
  flex-direction: column;
  align-items: flex-end;
  transform: translateY(-50%);

  &-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    letter-spacing: 1px;
  }

  &-text {
    color: $color-yellow;
    pointer-events: none;
    margin-right: 8px;
    font: $BARON_10;
  }
}

.step {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: $color-green-grey;
  margin: 5px 0;
  cursor: pointer;
  transition:
    background-color 0.4s ease-in-out,
    opacity 0.4s ease-in-out,
    transform 0.4s ease-in-out;
  opacity: 50%;

  &:hover:not(.active) {
    background-color: $color-yellow;
    scale: 1.2;

    ~ .stepper-item-text {
      animation: translate-x 0.4s forwards ease-in-out;
    }
  }

  &.active {
    background-color: $color-yellow;
    opacity: 100%;
    cursor: initial;
  }
}

@media (min-width: $tablet-breakpoint) {
  .stepper-item {
    display: flex;
  }
}
