@import '@styles/variables';

.terminal-wrapper {
  display: inline;
  align-items: center;
  position: relative;
  font: $APERCU_BOLD_40;
  color: $color-green-grey;
  font-size: 8vw;
}

.terminal-content {
  display: inline;
  white-space: pre-wrap;
  line-height: 1.5;
}

.cursor {
  background-color: $color-green-grey;
  width: 4px;
  height: 1em;
  display: inline-block;
  margin: 0 0 auto 10px;
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

@media (min-width: $tablet-breakpoint) {
  .terminal-wrapper {
    font-size: $s_60;
  }
}

@media (min-width: $desktop-breakpoint) {
  .terminal-wrapper {
    font-size: $s_60;
  }
}
