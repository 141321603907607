@import '@styles/variables.scss';

.home-knowledges {
  background-color: $color-brown;
  color: $color-green-grey;

  &-main-container {
    display: flex;
    flex-direction: column;
    gap: $s_40;
    width: 100%;
    height: 80vh;
  }

  &-list {
    display: flex;
    gap: $s_12;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 90px;
  }

  &-description {
    margin-top: $s_32;
    display: flex;
    height: 250px;
    transition:
      height 1s ease,
      padding 0.5s ease;
    overflow: visible;
    width: Calc(100vw - 2 * (var(--section-container-padding) + $s_16));
    margin-left: $s_16;
    margin-right: $s_16;

    &.first {
      height: 0;
    }

    &-name {
      color: $color-grey;
      font: $APERCU_MONO_BOLD_20;
      font-size: clamp(0.2rem, 5vw, $s_20);
      margin-bottom: 8px;
    }
  }

  &-item {
    display: flex;
    width: Calc(50% - 6px);
  }
}

@media (min-width: $tablet-breakpoint) {
  .home-knowledges {
    &-main-container {
      height: 50vh;
    }

    &-list {
      flex-wrap: nowrap;
      margin-top: 100px;
      margin-left: $s_32;
      margin-right: $s_32;
    }

    &-item {
      width: 100%;
    }

    &-description {
      height: 150px;
      width: Calc(100vw - 2 * (var(--section-container-padding) + $s_16));

      &-name {
        font-size: $s_20;
      }
    }
  }
}

@media (min-width: $desktop-breakpoint) {
  .home-knowledges {
    &-list {
      margin-top: $s_50;
      margin-left: $s_40;
      margin-right: $s_40;
    }

    &-description {
      height: 200px;
      margin-top: 70px;
      max-width: 100%;
      width: Calc(100vw - 2 * (var(--section-container-padding) + $s_32));
      margin-left: $s_32;
      margin-right: $s_32;

      &-name {
        font-size: $s_24;
      }
    }
  }
}
