@import "@styles/variables.scss";

.home-foreword {
  background-color: $color-grey;
  color: $color-blue;

  &-main-container {
    display: flex;
    flex-direction: column;
    gap: $s_40;
    padding-bottom: 2 * $s_120;
  }
}