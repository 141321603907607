@import '@styles/variables.scss';

.loading-button {
  &-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: Calc(100vw - 2 * $section-container-padding);
  }

  &-container {
    align-items: center;
    display: flex;
    transition:
      opacity 0.4s ease 0.5s,
      max-height 0.5s ease;
    opacity: 1;
    position: absolute;

    &.hide {
      opacity: 0;
      transition:
        opacity 0.4s ease,
        max-height 0.5s ease;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
