@import '@styles/variables';

.mouse {
	&-scroll {
		&-button {
			width: 4px;
			height: 10px;
			background-color: $color-green-grey;
			border-radius: 16px;
			position: relative;
			top: 15%;
			left: Calc(50% - 2px);
			animation: bounceTranslate 3s infinite ease-in-out;
			transition: background-color .4s ease-in-out;
		}

		&-container {
			width: 28px;
			height: 40px;
			border-style: solid;
			border-width: 3px;
			border-color: inherit;
			border-radius: 16px;
		}
	}
	&-main-container {
		position: absolute;
		left: Calc(50vw - 14px);
		bottom: 5vh;
		cursor: pointer;
		border-color: $color-green-grey;

		animation: bounceOpacity 3s infinite ease-in-out;
		transition:
			transform 0.4s ease-in-out,
			border-color 0.4s ease-in-out;
		&:hover {
			border-color: $color-yellow;
			transform: scale(1.1);
		}
		&:hover .mouse-scroll-button {
			background-color: $color-yellow;
		}
	}
}

@keyframes bounceTranslate {
	0% {
		transform: translateY(0);
	}
	20% {
		transform: translateY(-2px);
	}
	40% {
		transform: translateY(12px);
	}
	50% {
		transform: translateY(10px);
	}
	70% {
		transform: translateY(-3px);
	}
	80% {
		transform: translateY(0px);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes bounceOpacity {
	0% {
		opacity: 1;
	}
	20% {
		opacity: 1;
	}
	40% {
		opacity: 0.5;
	}
	50% {
		opacity: 0.5;
	}
	70% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
