@import '@styles/variables.scss';

.knowledge {
  display: flex;
  width: 100%;
  min-height: 80px;
  min-width: 80px;

  &-container {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 15px;
    justify-content: center;
    min-width: 80px;
    transition:
      color 0.5s ease-in-out,
      box-shadow 0.5s ease-in-out;
  }

  &-main-container {
    @extend .knowledge-container;
    color: $color-green-grey;

    &:hover {
      color: $color-yellow-g70;
      animation: pulse 2.4s infinite ease-out;
    }

    &-active {
      @extend .knowledge-container;
      cursor: initial;
      color: #ffa61f;
      transform: scale(1.1);
    }
  }

  &-name {
    font: $APERCU_MONO_BOLD_16;
  }

  &-icon {
    color: inherit;
    font-size: 3em;
  }
}

@media (min-width: $tablet-breakpoint) {
  .knowledge {
    &-container {
      width: 100%;
      min-width: 80px;
    }

    &-name {
      font-size: $s_16;
    }
  }
}

@media (min-width: $desktop-breakpoint) {
  .knowledge {
    &-container {
      min-width: 165px;
    }

    &-name {
      font-size: $s_24;
    }
  }
}
