$section-container-padding: var(--section-container-padding);

$tablet-breakpoint: 768px;
$desktop-breakpoint: 1024px;


$color_white: #ffffffff;
$color-blue-g70: #539dc6;
$color-blue-g60: #337599;
$color-blue: #1f485e;
$color-blue-g40: #193b4d;
$color-blue-g30: #132c39;
$color-blue-g20: #0d1d26;
$color-blue-g10: #060f13;

$color-yellow-g70: #ffc878;
$color-yellow-g60: #ffb74b;
$color-yellow: #ffa61f;
$color-yellow-g40: #e48900;
$color-yellow-g30: #ab6700;

$color-brown-g60: #99787e;
$color-brown: #7a5c61;
$color-brown-g40: #624a4e;
$color-brown-g30: #49373a;

$color-grey-g60: #b0bfbb;
$color-grey: #9caea9;
$color-grey-g40: #78918a;
$color-grey-g30: #5a6d68;
$color-grey-g20: #3c4945;
$color-grey-g10: #1e2423;

$color-green-grey: #ccdad1;
$color-green-grey-g40: #9cb7a5;
$color-green-grey-g30: #6b9379;
$color-green-grey-g20: #476251;
$color-green-grey-g10: #243128;

$s_4: 4px;
$s_8: 8px;
$s_10: 10px;
$s_12: 12px;
$s_14: 14px;
$s_16: 16px;
$s_18: 18px;
$s_20: 20px;
$s_20: 20px;
$s_24: 24px;
$s_26: 26px;
$s_32: 32px;
$s_40: 40px;
$s_50: 50px;
$s_60: 60px;
$s_80: 80px;
$s_120: 120px;

$APERCU_16:
  16px/1.5 Apercu,
  sans-serif;
$APERCU_14:
  14px/1.5 Apercu,
  sans-serif;
$APERCU_ITALIC_12:
  italic 12px/1.5 Apercu,
  sans-serif;
$APERCU_ITALIC_16:
  italic 16px/1.5 Apercu,
  sans-serif;
$APERCU_BOLD_14:
  bold 14px/1.5 Apercu,
  sans-serif;
$APERCU_BOLD_16:
  bold 16px/1.5 Apercu,
  sans-serif;
$APERCU_BOLD_20:
  bold 20px/1.5 Apercu,
  sans-serif;
$APERCU_BOLD_26:
  bold 26px/1.5 Apercu,
  sans-serif;
$APERCU_BOLD_40:
  bold 40px/1.5 Apercu,
  sans-serif;
$APERCU_BOLD_60:
  bold 60px/1.5 Apercu,
  sans-serif;

$APERCU_MONO_14:
  14px/1.5 Apercu Mono,
  sans-serif;
$APERCU_MONO_16:
  16px/1.5 Apercu Mono,
  sans-serif;
$APERCU_MONO_20:
  20px/1.5 Apercu Mono,
  sans-serif;
$APERCU_MONO_24:
  24px/1.5 Apercu Mono,
  sans-serif;

$APERCU_MONO_BOLD_16:
  bold 16px/1.5 Apercu Mono,
  sans-serif;
$APERCU_MONO_BOLD_18:
  bold 18px/1.5 Apercu Mono,
  sans-serif;
$APERCU_MONO_BOLD_20:
  bold 20px/1.5 Apercu Mono,
  sans-serif;
$APERCU_MONO_BOLD_24:
  bold 24px/1.5 Apercu Mono,
  sans-serif;

$BARON_10:
  10px/1.5 Baron,
  sans-serif;
$BARON_40:
  40px/1.5 Baron,
  sans-serif;
$BARON_60:
  60px/1.5 Baron,
  sans-serif;

@font-face {
  font-family: 'Apercu Mono';
  src:
    url('../fonts/ApercuPro-Mono.ttf') format('ttf'),
    url('../fonts/ApercuPro-Mono.woff') format('woff'),
    url('../fonts/ApercuPro-Mono.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Baron';
  src:
    url('../fonts/BaronNeue.ttf') format('ttf'),
    url('../fonts/BaronNeue.woff') format('woff'),
    url('../fonts/BaronNeue.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

// LAYOUT VARIABLES
$section-max-width: 1140px;

:root {
  --section-container-padding: #{$s_16};
}

@media (min-width: $tablet-breakpoint) {
  :root {
    --section-container-padding: #{$s_32};
  }
}

@media (min-width: $desktop-breakpoint) {
  :root {
    --section-container-padding: #{$s_40};
  }
}
