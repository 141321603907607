@import "@styles/variables.scss";

.infinite-carousel {
	position: absolute;
	right: 0;
	width: 100vw;
	bottom: -95vh;
	height: 120px;
	align-content: center;

	&-main-container {
		display: flex;
		width: 100vw;
		overflow: hidden;
		padding: 0;
		margin: 0;
		height: 100%;
		align-items: center;

		&:hover .infinite-carousel-skills-container {
			animation-play-state: paused;
		}
	}

	&-skills-container {
		display: flex;
		flex-direction: row;
		padding: 0;
		bottom: -80vh;
		animation: scroll 30s linear infinite;
	}
}

@keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(Calc(-100%/3));
	}
}

@media (min-width: $tablet-breakpoint) {
	.infinite-carousel {
		bottom: -85vh;
	}
}

@media (min-width: $desktop-breakpoint) {
	.infinite-carousel {
		bottom: -80vh;
	}
}