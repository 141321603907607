@import '@styles/variables.scss';

:root {
  --dot-inner-size: 20px;
  --dot-border: 4px;
  --dot-size: (var(--dot-inner-size) + (2 * var(--dot-border)));
  --link-margin: 10px;
  --item-container-size: 22vw;
  --dot-scale: 0.6;
  --link-width: Calc(
    var(--item-container-size) - (2 * var(--link-margin)) -
      (var(--dot-size) * var(--dot-scale))
  );
  --linkTranslateX: Calc(
    -50% - (var(--link-margin) + (var(--dot-size) * var(--dot-scale)) / 2)
  );
}

.timeline {
  &-main-container {
    display: flex;
    flex-direction: column;
    gap: 5vh;
    height: 70vh;
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $s_12;
    min-height: 60px;
    cursor: pointer;
    color: $color-green-grey;
    transition: color 0.5s ease-in-out;
    font: $APERCU_BOLD_14;
    text-align: center;
    width: var(--item-container-size);

    &.actif {
      color: $color-blue;

      .timeline-item-company {
        color: $color-grey-g30;
      }

      .timeline-item-dot {
        background-color: $color-yellow;
        z-index: 3;
        display: block;
      }

      .timeline-item-dot-link {
        background-color: $color-blue !important;
      }
    }

    &.current {
      .timeline-item-role,
      .timeline-item-date {
        transition:
          transform 0.5s ease-in-out,
          color 0.5s ease-in-out;
        color: $color-blue;
      }

      .timeline-item-company {
        transition:
          transform 0.5s ease-in-out,
          color 0.5s ease-in-out;
        color: $color-brown;
      }

      .timeline-item-date,
      .timeline-item-content {
        transition:
          transform 0.5s ease-in-out,
          color 0.5s ease-in-out;
        transform: scale(1);
      }

      .timeline-item-dot {
        transition:
          transform 0.5s ease-in-out,
          color 0.5s ease-in-out;
        transform: scale(1);
        border-color: $color-blue;
      }
    }

    &-container {
      display: flex;
      width: auto;
      justify-content: center;
      flex-direction: row-reverse;
      overflow: hidden;
    }

    &-content {
      display: flex;
      flex-direction: column;
    }

    &-dot {
      transition:
        background-color 0.5s ease-in-out,
        border 0.5s ease-in-out,
        transform 0.5s ease-in-out;
      transform: scale(var(--dot-scale));
      width: var(--dot-inner-size);
      height: var(--dot-inner-size);
      display: flex;
      background-color: $color-yellow-g70;
      border-radius: 50%;
      border: 4px solid transparent;
      z-index: 1;

      &-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-link {
        transition: background-color 0.5s ease-in-out;
        content: '';
        position: absolute;
        height: 5px;
        background-color: $color-green-grey;
        width: var(--link-width);
        transform: translateX(var(--linkTranslateX));
        z-index: -1;
        border-radius: 5px;
      }
    }

    &-content {
      transition:
        transform 0.5s ease-in-out,
        color 0.5s ease-in-out;
      transform: scale(0.9);
    }

    &-date {
      transition:
        transform 0.5s ease-in-out,
        color 0.5s ease-in-out;
      transform: scale(0.9);
      font-size: $s_12;
    }

    &-company {
      transition:
        transform 0.5s ease-in-out,
        color 0.5s ease-in-out;
      font-size: $s_12;
      color: $color-green-grey;
    }

    &:last-child .timeline-item-dot-link {
      background: none !important;
    }
  }
}

.selected-job {
  &-main-container {
    display: flex;
    margin: 0 $s_12 auto $s_12;
    height: auto;
  }

  &-container {
    background-color: $color-grey-g60;
    border: 10px;
    padding: $s-32;
    border-radius: 8px;
    box-shadow: 15px 25px 45px 3px rgba(66, 66, 66, 0.2);
    height: fit-content;
  }

  &-header {
    &-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
      margin-bottom: $s_24;
    }

    &-title {
      display: flex;
      flex-direction: column;
      align-items: end;
      text-align: end;

      &-role {
        font: $APERCU_BOLD_20;
      }

      &-company {
        font: $APERCU_ITALIC_12;
        color: $color-brown;
      }
    }

    &-date {
      font: $APERCU_ITALIC_12;
    }
  }

  &-description {
    font: $APERCU_MONO_14;
  }

  &-realisation {
    font-size: $s_12;
    &:last-child {
      padding-bottom: 20px;
    }
    &:first-child {
      padding-top: 20px;
    }
  }

  &-realisations {
    display: flex;
    flex-direction: column;
    margin: 0 15px 45px 15px;
    font: $APERCU_MONO_14;

    &-container {
      display: flex;
      overflow: scroll;
      max-height: 30vh;
    }

    &-scroll-fade {
      position: absolute;
      top: Calc(100% - $s_32 - $s_24);
      left: $s-32;
      width: Calc(100% - 2 * $s-32);
      height: $s_24;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        $color-grey-g60
      );
      pointer-events: none;

      &-top {
        position: absolute;
        left: $s-32;
        width: Calc(100% - 2 * $s-32);
        height: 20px;
        background: linear-gradient(
                        to top,
                        rgba(255, 255, 255, 0),
                        $color-grey-g60
        );
        pointer-events: none;
      }
    }
  }
}

@media (min-width: $tablet-breakpoint) {
  :root {
    --item-container-size: 140px;
  }

  .timeline {
    &-main-container {
      height: auto;
    }

    &-item {
      font-size: $s_16;
      &-company,
      &-date {
        font-size: $s_14;
      }

      &-dot {
        &-link {
        }
      }
    }
  }

  .selected-job {
    &-description {
      font-size: $s_20;
    }

    &-header {
      &-title {
        &-role {
          font-size: $s_26;
        }

        &-company {
          font-size: $s_16;
        }
      }

      &-date {
        font-size: $s_16;
      }
    }

    &-realisations {
      max-height: none;
      padding: 0;
      margin-bottom: 0;

      &-scroll-fade {
        display: none;
      }
    }

    &-realisation {
      font-size: $s_14;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

@media (min-width: $desktop-breakpoint) {
  :root {
    --item-container-size: 100px;
    --dot-inner-size: 25px;
    --link-width: Calc(
      var(--item-container-size) - (2 * var(--link-margin)) -
        (var(--dot-size) * var(--dot-scale))
    );
    --linkTranslateY: Calc(
      50% + (var(--link-margin) + (var(--dot-size) * var(--dot-scale)) / 2)
    );
  }

  .selected-job {
    &-main-container {
      width: 70vw;
      margin: auto $s_12;
    }
  }

  .timeline {
    &-main-container {
      flex-direction: row;
      gap: 0;
      width: Calc(100vw - (2 * var(--section-container-padding)));
      max-width: $section-max-width;
    }

    &-item {
      width: 100%;
      flex-direction: row;
      height: var(--item-container-size);
      margin: 0;

      &-date {
        width: 25%;
      }

      &-content {
        width: 100%;
        text-align: left;
      }

      &-dot {
        &-link {
          width: 5px;
          height: var(--link-width);
          background-color: $color-green-grey;
          transform: translate(0, var(--linkTranslateY));
        }
      }

      &-container {
        flex-direction: column;
        width: 30vw;
      }
    }
  }
}
