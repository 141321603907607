@import '@styles/variables.scss';

.home-contact {
  background-color: $color-blue;
  color: $color-yellow;

  &-main-container {
    display: flex;
    flex-direction: column;
    gap: $s_40;
    width: 100vw;
  }

  &-form {
    display: flex;
    flex-direction: column;
    gap: $s_20;
    align-items: center;
    width: 100%;

    &-container {
      display: flex;
      flex-direction: row;
      gap: $s_20;

      .loading-button-main-container {
        margin: $s_20 auto 0;
        width: 50vw;
      }
    }
  }
}

@media (min-width: $tablet-breakpoint) {
  .home-contact {
    &-main-container {
      width: 70vw;
      margin: 0 auto;
    }

    &-form {
      width: 70%;
      margin: auto;
      max-width: 50vw;

      &-container {
        .loading-button-main-container {
          width: 50%;
        }
      }
    }
  }
}
@media (min-width: $desktop-breakpoint) {
  .home-contact {
    &-form {
      max-width: 40vw;
    }
  }
}