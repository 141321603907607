@import '@styles/variables.scss';

//OVERWRITES
* {
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

*::-webkit-scrollbar {
  /* WebKit browsers */
  display: none;
}

// UTILITAIRES
.pf-fullscreen {
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pf-section-container {
  display: flex;
  font: $APERCU_MONO_16;
  font-size: clamp(0.2rem, 4vw, 16px);
  letter-spacing: 0.4px;
  word-spacing: -0.1em;
  text-wrap: pretty;
  padding: var(--section-container-padding);
  max-width: Calc(100% - 2 * var(--section-container-padding));
}

.pf-section-title {
  font: $BARON_40;
}

.pf-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font: $APERCU_MONO_BOLD_18;
  border: none;
  border-radius: $s_8;
  cursor: pointer;
  transition:
    background-color 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out,
    width .4s ease-in-out .1s,
    border-radius .2s ease-in-out;
  background-color: $color-yellow;
  color: $color-white;
  width: 100%;
  height: 3.5em;

  &.success {
    pointer-events: none;
    background-color: green;
    width: 3.5em;
    border-radius: 150px;
    svg {
      height: 1.5em;
      width: 1.5em;
    }
  }

  &.error {
    pointer-events: none;
    background-color: red;
    width: 3.5em;
    border-radius: 150px;
    svg {
      height: 1.7em;
      width: 1.7em;
    }
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    background-color: rgba(255, 166, 31, 0.5);
  }

  &:active {
    background-color: $color-blue;
    box-shadow: 0px 4px 30px 8px rgba(20, 20, 20, 0.4);
  }
}

.pf-spinner {
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  height: 3em;
  color: $color-white;
}

.pf-animation-delay {
    transition-delay: .5s;
}

//ANIMATIONS
.pf-shiny-text {
  background: linear-gradient(
    23deg,
    #000,
    #000,
    #000,
    #000,
    $color-blue,
    #000,
    #000,
    #000
  );

  background-size: 320% 2000%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 5.5s linear infinite;

  &:hover {
    animation: none;
    background: transparent;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: unset;
  }
}

@keyframes shine {
  0% {
    background-position: 147%;
  }
  40% {
    background-position: 147%;
  }
  50% {
    background-position: 0;
  }
  80% {
    background-position: 0;
  }
  89% {
    background-position: 147%;
  }
  100% {
    background-position: 147%;
  }
}

.pf-appear-animation {
  opacity: 0;

  &.active {
    animation: appear 1s forwards ease-out;
    animation-delay: 0.1s;

    &-fast {
      animation: appear-fast 0.7s forwards ease-out;
    }
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: translate3d(0, 10vh, 0) skew(0deg, 6deg);
    transform-style: preserve-3d;
  }
  100% {
    opacity: 1;
    transform: skew(0deg, 0deg);
    transform-style: preserve-3d;
  }
}

@keyframes appear-fast {
  0% {
    opacity: 0;
    transform: translate3d(0, 4vh, 0) skew(0deg, 5deg) scale(0.9);
    transform-style: preserve-3d;
  }
  100% {
    opacity: 1;
    transform: skew(0deg, 0deg) scale(1);
    transform-style: preserve-3d;
  }
}

.pf-fade-in-animation {
  opacity: 0;

  &.active {
    animation: fadeIn 1s forwards ease-in-out;
    animation-delay: 0.2s;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pf-translate-x {
  opacity: 0;
  transform: translateX(30%);
}

@keyframes translate-x {
  0% {
    opacity: 0;
    transform: translateX(30%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes pulse {
  0%,
  10%,
  60%,
  100% {
    transform: scale(1);
    filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.75));
    color: $color-yellow-g70;
  }
  25% {
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.8));
    color: $color-yellow;
    transform: scale(0.9);
  }
  30% {
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.85));
    transform: scale(0.95);
    color: $color-yellow-g60;
  }
  35% {
    transform: scale(0.92);
    color: $color-yellow;
    filter: drop-shadow(0px 0px 17px rgba(0, 0, 0, 0.82));
  }
}

@media (min-width: $tablet-breakpoint) {
  .pf-button {
    &:hover {
      color: $color-yellow;
      background-color: $color-blue;
      box-shadow: 0px 4px 30px 8px rgba(20, 20, 20, 0.4);
    }
  }

  .pf-section-container {
    font-size: 18px;
  }

  .pf-section-title {
    font-size: $s_50;
  }
}

@media (min-width: $desktop-breakpoint) {
  .pf-section-container {
    letter-spacing: 0;
    word-spacing: 0;
    max-width: $section-max-width;
    font-size: 20px;
  }

  .pf-section-title {
    font-size: $s_60;
  }
}
