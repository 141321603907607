@import "@styles/variables.scss";

.pf-skill {
    font: $APERCU_MONO_24;
    color: $color-yellow;
    background-color: rgba(31, 72, 94, 0.8);
    display: flex;
    width: fit-content;
    padding: 16px 16px;
    border-radius: 8px;
    align-items: center;
    justify-content: left;
    gap: 8px;
    text-wrap: nowrap;
    margin-right: 16px;
    transition: transform 0.5s ease, box-shadow 0.3s ease;
    cursor: pointer;

    &:hover {
        background-color: $color-blue;
        transform: translateY(-5px) scale(1.05);
        box-shadow: 0 14px 16px rgba(0, 0, 0, .3);
    }
}