@import '@styles/variables';

.home-intro {
	background-color: $color-blue;

	&-main-container {
		display: flex;
		flex-direction: column;
		text-align: start;
		padding-bottom: $s_80;
		gap: $s_60;

		&-description {
			letter-spacing: .4px;

			a {
				font-weight: bold;
				cursor: pointer;
				transition: color .8s cubic-bezier(0.25, 1, 0.5, 1);
				transform-origin: left;
				position: relative;

				&:hover {
					color: $color-yellow;
					&::after {
						transform: scaleX(1);
					}
				}

				&::after {
					content: '';
					position: absolute;
					left: 0;
					bottom: -4px;
					height: 2px;
					width: 100%;
					background: $color-yellow;
					transform: scaleX(0);
					transform-origin: left;
					transition: transform 0.5s ease;
				}
			}
		}
	}
}

.fade-in {
	opacity: 0;
	transition: opacity 0.4s ease-in-out .1s;
}

.fade-in.show {
	opacity: 1;
}

@media (min-width: $tablet-breakpoint) {
	.home-intro {
		&-main-container {
			padding-bottom: $s_80;
		}
	}
}